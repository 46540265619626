import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    component: () => import("@/views/pages/Login.vue"),
    name: "login",
    meta: {
      pageTitle: "登入",
      breadcrumbs: ["登入"],
    },
  },
  {
    path: "/",
    redirect: "/dashboard",
    meta: {
      middleware: "auth",
    },
    component: () => import("@/views/layout/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard.vue"),
        meta: {
          pageTitle: "首頁",
          breadcrumbs: ["首頁"],
        },
      },
      {
        path: "/users",
        name: "users",
        children: [
          {
            path: "invite-code",
            name: "users-invite-code",
            component: () => import("@/views/pages/Users/InviteCode.vue"),
            meta: {
              pageTitle: "邀請函",
              breadcrumbs: ["邀請函"],
            },
          },
          {
            path: "admin-login",
            name: "users-admin-login",
            component: () => import("@/views/pages/Users/AdminLogin.vue"),
            meta: {
              pageTitle: "用戶管理員登入",
              breadcrumbs: ["用戶管理員登入"],
            },
          },
          {
            path: "",
            name: "users-index",
            component: () => import("@/views/pages/Users/Index.vue"),
            meta: {
              pageTitle: "所有用戶",
              breadcrumbs: ["所有用戶"],
            },
          },
        ],
      },
      {
        path: "/messages",
        name: "messages",
        children: [
          {
            path: "send",
            name: "message-send",
            component: () => import("@/views/pages/Messages/MessageSend.vue"),
            meta: {
              pageTitle: "訊息發送",
              breadcrumbs: ["訊息發送"],
            },
          },
          {
            path: "templates",
            name: "message-templates",
            component: () =>
              import("@/views/pages/Messages/MessageTemplate.vue"),
            meta: {
              pageTitle: "所有訊息",
              breadcrumbs: ["所有訊息"],
            },
          },
          {
            path: "statics",
            name: "message-statics",
            component: () =>
              import("@/views/pages/Messages/MessageStatics.vue"),
            meta: {
              pageTitle: "訊息統計",
              breadcrumbs: ["訊息統計"],
            },
          },
          {
            path: "tasks",
            name: "message-tasks",
            component: () => import("@/views/pages/Messages/MessageTask.vue"),
            meta: {
              pageTitle: "所有任务",
              breadcrumbs: ["所有任务"],
            },
          },
          {
            path: "user-message-statistics",
            name: "user-message-statistics",
            component: () =>
              import("@/views/pages/Messages/UserMessageStatistics.vue"),
            meta: {
              pageTitle: "訊息統計",
              breadcrumbs: ["訊息統計"],
            },
          },
          {
            path: "",
            name: "messages-index",
            component: () => import("@/views/pages/Messages/Index.vue"),
            meta: {
              pageTitle: "所有訊息",
              breadcrumbs: ["所有訊息"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, {
    token: JwtService.getToken(),
  });
  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
